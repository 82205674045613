
* {
  box-sizing: border-box;
}

:root {
  --smallscreen: 680px;
  --font-wght: 50;
  --font-affa: 50;
  --font-ital: 0;
  --font-craz: 0;
  --font-rund: 50;

  --copy-size: 14px;
  --copy-large-size: 19px;
  --headline-size: 32px;
}

@font-face {
  font-family: 'PartnerVF';
  src: url('./assets/PartnerVF.ttf') format('truetype');
  //font-weight: 100 900; /* Define the font weights you want to use */
  //font-stretch: 50% 200%; /* Define the font stretch range */
}

body, mark {
  font-family: 'PartnerVF', sans-serif; /* Use your variable font */
  font-feature-settings: "calt" 1, "liga" 1, "rlig" 1, "rvrn" 1, "kern" 1, "rclt" 1;
  font-variation-settings: "wght" 25, "affa" 0, "ital" 0, "craz" 0, "rund" 0;
}

.variable {
  font-variation-settings: "wght" var(--font-wght), "affa" var(--font-affa), "ital" var(--font-ital), "craz" var(--font-craz), "rund" var(--font-rund);
}

body {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-height: 100svh;
  font-size: var(--copy-size);
}

.container {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  height: 100svh;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  display: block;
  position: relative;
  overflow: hidden;

  &-scroll {
    overflow-y: scroll !important;

    &::-webkit-scrollbar {
      display: none;
    }
    
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

  }
  
  
  @media screen and (min-width: 440px) {
    max-width: 420px;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0px 16px 34px 0px rgba(97, 0, 0, 0.10), 0px 62px 62px 0px rgba(97, 0, 0, 0.09), 0px 141px 84px 0px rgba(97, 0, 0, 0.05), 0px 250px 100px 0px rgba(97, 0, 0, 0.01), 0px 391px 109px 0px rgba(97, 0, 0, 0.00);
    border: 1px solid #f0efef;
    // border: 1px solid #FECAFE;
    height: calc(100svh - 2rem);
    max-height: 800px;
  }

}

h1 {
  font-size: var(--headline-size);
  margin: 0;
  margin-bottom: 0;
  font-weight: 25;
}

.question {
  margin-bottom: 20px;  
}


.paragraph-narrow {
  width: 60%;
  //min-width: 280px;
  margin-top: -5px;
}

i {
  font-variation-settings: "wght" 50, "affa" 0, "ital" 100, "craz" 0, "rund" 0;
}


.card{
  width: 100%;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: space-between;

  &-head {
    text-align: left;
    transition: opacity 500ms;
  }

  &-main {
    display: flex;
    flex-direction: column;
    gap: 6px;
    transition: opacity 500ms;
  }

  &-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  &-options {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .hide-this-element {
    opacity: 0;
    pointer-events: none;
    transition: opacity 500ms;
  }
}

.option-button {
  display: inline-flex;
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  // padding: 5px 6px 0px 5px;
  padding: 10px 10px 5px 10px;
  justify-content: flex-start;
  font-size: var(--copy-size);
  text-align: left;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #000;
  background: #FFF;
  color: #000;
  cursor: pointer;

  &.active {
    background: #FECAFE;
    border-color: transparent;
  }

  &--image {
    padding: 0;
    overflow: hidden;
    transition: opacity 300ms;

    &.inactive {
      opacity: 0.4;
    }
  }

  .option-image {
    width:100%;
  }
}

.startscreen {
  background: #FECAFE;
  transition: background 200ms;
}

.legalscreen, .infoscreen {
  background: #000;
  color: #fff;
  min-height: 100%;
  // overflow-y: scroll;
  transition: background 200ms;
  padding: 20px;
  text-align: left;

  p {
    width: 60%;
    min-width: 280px;
  }

  a {
    color:white;
  }

  .button-secondary {
    border-color: white;
  }
}

.infoscreen {
  background: #FAC0FF;
  color: #000;
  
  .button-secondary {
    border-color: black;
  }

  a{
    color:black !important;
  }
}

.button-primary {
  display: flex;
  width: 100%;
  // padding: 4px 20px;
  padding: 10px 20px 0px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #FAC0FF;
  color: #000;
  transition: background 200ms, opacity 200ms;
  border: none;
  font-size: var(--headline-size);
  font-family: inherit;
  font-weight: 25;
  cursor: pointer;
  box-shadow: 3px 3px 1px 0px rgba(255, 255, 255, 0.30) inset, -2px -2px 1px 0px rgba(0, 0, 0, 0.40) inset;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }


  &:hover {
    background: #C49AC7;
  }
}

.button-secondary {
  display: inline-flex;
  padding: 5px 6px 0px 5px;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  font-weight: 25;
  border-radius: 6px;
  border: 1px solid #000;
  background: rgba(255, 255, 255, 0.00);
  color: #000;
  text-decoration: none;
  color: inherit;
  line-height: 1;
}

.heart {
  font-size: var(--headline-size);
  text-align: left;
}

.heart-startscreen {
  display: block;
  width: 100%;
  height: 250px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  font-size: 120px;
  padding: 10px;
}

.values-startscreen {
  position: absolute;
  top:20px;
  left:0;
  right: 0;
  bottom: auto;
  pointer-events:none;
}

.pagination {
  position: absolute;
  margin: 0;
}

.topic {
  text-indent: 64px;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 3px;
}

.question {
  margin: 0;
  text-indent: 64px;
  line-height: 90%;
  font-weight: 25;
  font-size: var(--headline-size);
}

.result {
  font-size: var(--copy-large-size);
  line-height: 120%;

  mark {
    //display: inline-flex;
    // padding: 5px 6px 6px 5px;
    padding: 5px 6px 0 5px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    font-size: var(--copy-size);
    border-radius: 6px;
    border: 1px solid #000;
    background: rgba(255, 255, 255, 0.00);

    &.active {
      background: #FECAFE;
    }

    &:after {
      content: '↓';
      display: inline-block;
      position: relative;
    }
  }
}


.options-overlay {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
  background: #fff;
  border-top: 1px solid black;
  gap: 8px;
  transform: translate3d(0, 100%, 0);
  transition: transform 250ms;
  
  &.visible {
    display: flex;
    transform: translate3d(0, 0, 0);
  }

  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .option-button {
      width: 100%;
    }
  }

  &-close-outline-white {
    background: transparent;
    text-decoration: none;
    border: 1px solid white;
    color: white;
    float: right;
  }
  
  &-close-outline-black {
    background: transparent;
    text-decoration: none;
    border: 1px solid black;
    color: black;
    float: right;
  }
}



.debug {
  position: fixed;
  top: 0;
  left: 0;
  background: red;
  color: white;
  display: none;
  padding: 10px;

  &.visible {
    display: block;
  }
}